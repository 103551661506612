'use client';

import CloseIcon from '@mui/icons-material/Close';
import type { Theme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type FC, type ReactNode, useCallback, useState } from 'react';

type Props = {
  children: ReactNode;
  isDefaultOpen?: boolean;
  isQuestionBtn?: boolean;
  onClose?: () => void;
};

export const ReadingKeyModal: FC<Props> = (props) => {
  const {
    children,
    isDefaultOpen = false,
    isQuestionBtn = false,
    onClose = undefined,
  } = props;

  const isUpToMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [open, setOpen] = useState<boolean>(isDefaultOpen && isUpToMd);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setOpen, onClose]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        className="reading-key-btn"
        sx={{
          p: 0,
          minWidth: '1.5rem',
          lineHeight: '1.5rem',
          boxShadow: '0 0 5px',
          background: 'white',
          color: '#2D5B5B',
          fontWeight: 'bolder',
          '& .MuiButton-startIcon': {
            display: 'none',
          },
          '&:hover': {
            boxShadow: '0 0 10px',
            background: 'white',
          },
        }}
      >
        {isQuestionBtn ? '?' : 'i'}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { none: '100%', md: 'fit-content' },
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflow: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '4px',
              p: 4,
            }}
            component="article"
          >
            <Box component="div" position="relative">
              <Box
                component="div"
                position="absolute"
                top={0}
                right={0}
                zIndex={10}
                sx={{ transform: 'translate(50%, -50%)' }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
