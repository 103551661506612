'use client';

import { FormHelperText, Stack } from '@mui/material';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form';
import { Otp, type OtpProps } from '../otp';

export type OtpInputProps<T extends FieldValues = FieldValues> = Omit<
  OtpProps,
  'name' | 'onChange' | 'value'
> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  component?: typeof Otp;
};

export function OtpInput<TFieldValues extends FieldValues = FieldValues>(
  props: OtpInputProps<TFieldValues>
) {
  const { control, name, component: OtpComponent = Otp, ...baseProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, onBlur, ...field },
        fieldState: { invalid, error },
      }) => (
        <Stack>
          <OtpComponent {...baseProps} {...field} />
          {error && (
            <FormHelperText sx={{ alignSelf: 'center' }} error={!!error}>
              {error?.message}
            </FormHelperText>
          )}
        </Stack>
      )}
    />
  );
}
