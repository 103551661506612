'use client';

import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fr from 'date-fns/locale/fr';
import type { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const LocalizationProvider: FC<Props> = (props) => {
  const { children } = props;
  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      {children}
    </MuiLocalizationProvider>
  );
};
