'use client';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MuiToggleButtonGroup, {
  type ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import type { ReactNode } from 'react';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form';

export type ToggleButtonGroupProps<T extends FieldValues = FieldValues> = Omit<
  MuiToggleButtonGroupProps,
  'name'
> & {
  label?: ReactNode;
  helperText?: string;
  enforceAtLeastOneSelected?: boolean;
  transformToArray?: boolean;
  children: ReactNode;
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  /**
   * You override the MUI's MuiCheckbox component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of MuiCheckbox.
   */
  component?: typeof MuiToggleButtonGroup;
};

export function ToggleButtonGroupInput<
  TFieldValues extends FieldValues = FieldValues,
>(props: ToggleButtonGroupProps<TFieldValues>) {
  const {
    label = undefined,
    helperText = undefined,
    control,
    name,
    component: ToggleButtonGroupComponent = MuiToggleButtonGroup,
    exclusive = false,
    enforceAtLeastOneSelected = false,
    transformToArray = false,
    children,
    ...baseProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onBlur, onChange },
        fieldState: { invalid, error },
      }) => (
        <FormControl hiddenLabel={!label} error={invalid}>
          <FormLabel>{label}</FormLabel>
          <ToggleButtonGroupComponent
            {...baseProps}
            exclusive={exclusive}
            value={value}
            onBlur={onBlur}
            onChange={(event, val) => {
              if (enforceAtLeastOneSelected) {
                // don't allow unselecting the last item
                if (exclusive && val === null) return;
                if (!exclusive && val.length === 0) return;
              }

              if (exclusive && transformToArray) {
                if (Array.isArray(val)) {
                  onChange(val);
                } else {
                  if (!val) {
                    onChange([]);
                  } else {
                    onChange([val]);
                  }
                }
              } else {
                onChange(val);
              }

              if (typeof baseProps.onChange === 'function') {
                baseProps.onChange(event, val);
              }
            }}
          >
            {children}
          </ToggleButtonGroupComponent>
          {((invalid && error?.message) || helperText) && (
            <FormHelperText sx={{ marginLeft: 0 }}>
              {invalid && error?.message ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
