'use client';

import type { StyledComponent } from '@emotion/styled';
import Box, { type BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Center: StyledComponent<BoxProps> = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minWidth: '100%',
  height: '100%',
  minHeight: '100%',
});
