'use client';

import { Help } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { type FC, type ReactNode, useState } from 'react';

type Props = {
  children: ReactNode;
  isOpenByDefault?: boolean;
};

export const TipModal: FC<Props> = ({ children, isOpenByDefault = false }) => {
  const [open, setOpen] = useState(isOpenByDefault);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen} color="primary" size="small">
        <Help fontSize="small" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '4px',
              p: 4,
            }}
            component="article"
          >
            {children}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
