'use client';

import {
  DatePicker as MuiDatePicker,
  type DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';

export type DatePickerProps<TDate extends Date> = MuiDatePickerProps<TDate>;

export const DatePicker = MuiDatePicker;
