'use client';

import { Check } from '@mui/icons-material';
import type { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

const PoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.grey[400],
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.light,
    }),
    '& .PoStepIcon-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
    '& .PoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

export function PoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <PoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="PoStepIcon-completedIcon" />
      ) : (
        <div className="PoStepIcon-circle" />
      )}
    </PoStepIconRoot>
  );
}
