'use client';

import LinkedInLogo from '@/assets/logos/linked-in/light.png';
import Button, { type ButtonProps } from '@mui/material/Button';
import Image from 'next/image';
import type { FC } from 'react';

export const LinkedInButton: FC<ButtonProps> = (props) => (
  <Button
    {...props}
    sx={{
      borderRadius: 1,
      color: 'white',
      ...(props.disabled
        ? {
            filter: 'grayscale(1)',
          }
        : {}),
    }}
    color="linkedIn"
    startIcon={<Image src={LinkedInLogo} alt="LinkedIn" height={30} />}
    data-testid="linkedin-btn"
  >
    Se connecter avec LinkedIn
  </Button>
);
