'use client';

import { CircularProgress } from '@mui/material';
import MuiAutocomplete, {
  type AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions as muiCreateFilterOptions,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form';

export type AutocompleteInputProps<
  T,
  F extends FieldValues = FieldValues,
  M extends boolean | undefined = undefined,
  D extends boolean | undefined = undefined,
> = {
  name: Path<F>;
  control?: Control<F>;
  options: T[];
  loading?: boolean;
  autoHighlight?: boolean;
  multiple?: M;
  matchId?: boolean;
  required?: boolean;
  label?: TextFieldProps['label'];
  showCheckbox?: boolean;
  autocompleteProps?: Omit<
    MuiAutocompleteProps<T, M, D, any>,
    'name' | 'options' | 'loading' | 'renderInput' | 'onInputChange'
  >;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
  onSearch?: (newValue: string) => void;
};

type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};

export function AutocompleteInput<TFieldValues extends FieldValues>({
  textFieldProps,
  autocompleteProps,
  name,
  control,
  options,
  loading,
  autoHighlight,
  showCheckbox,
  required,
  multiple,
  matchId,
  label,
  onSearch,
}: AutocompleteInputProps<
  TFieldValues,
  AutoDefault | string | any,
  boolean | undefined,
  boolean | undefined
>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        let currentValue = multiple ? value || [] : value ?? null;
        if (matchId) {
          currentValue = multiple
            ? (value || []).map((i: any) =>
                options.find((j) => (j.id ?? j) === i)
              )
            : options.find((i) => (i.id ?? i) === value) ?? null;
        }
        return (
          <MuiAutocomplete
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            autoHighlight={autoHighlight}
            disableCloseOnSelect={
              typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
                ? autocompleteProps.disableCloseOnSelect
                : !!multiple
            }
            isOptionEqualToValue={
              autocompleteProps?.isOptionEqualToValue
                ? autocompleteProps.isOptionEqualToValue
                : (option, value) => {
                    return value ? option.id === (value?.id ?? value) : false;
                  }
            }
            getOptionLabel={
              autocompleteProps?.getOptionLabel
                ? autocompleteProps.getOptionLabel
                : (option: any) => {
                    return `${option?.label ?? option}`;
                  }
            }
            onChange={(event, value: any, reason, details) => {
              let changedVal = value;
              if (matchId) {
                changedVal = Array.isArray(value)
                  ? value.map((i: any) => i?.id ?? i)
                  : value?.id ?? value;
              }
              onChange(changedVal);
              if (autocompleteProps?.onChange) {
                autocompleteProps.onChange(event, value, reason, details);
              }
            }}
            onInputChange={(event, newValue) => {
              if (onSearch) {
                onSearch(newValue);
              }
            }}
            renderOption={
              autocompleteProps?.renderOption ??
              (showCheckbox
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                      {autocompleteProps?.getOptionLabel?.(option) ||
                        option.label ||
                        option}
                    </li>
                  )
                : undefined)
            }
            onBlur={(event) => {
              onBlur();
              if (typeof autocompleteProps?.onBlur === 'function') {
                autocompleteProps.onBlur(event);
              }
            }}
            renderInput={(params) => (
              <TextField
                name={name}
                required={required}
                label={label}
                {...textFieldProps}
                {...params}
                error={!!error}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  ...textFieldProps?.InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  ...textFieldProps?.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  ...textFieldProps?.inputProps,
                }}
                helperText={error ? error.message : textFieldProps?.helperText}
              />
            )}
          />
        );
      }}
    />
  );
}
