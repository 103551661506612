'use client';

import FranceTravailLogo from '@/assets/logos/france-travail/full.svg';
import Button, { type ButtonProps } from '@mui/material/Button';
import Image from 'next/image';
import type { FC } from 'react';

export const PoleEmploiButton: FC<ButtonProps> = (props) => (
  <Button
    {...props}
    sx={{
      borderWidth: 2,
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      ...(props.disabled
        ? {
            filter: 'grayscale(1)',
          }
        : {}),
    }}
    variant="outlined"
    color="poleEmploi"
    startIcon={
      <Image src={FranceTravailLogo} alt="Logo de Pole Emploi" height={30} />
    }
    data-testid="pole-emploi-btn"
  >
    Se connecter avec
  </Button>
);
