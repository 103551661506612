'use client';

import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { type FC, type MouseEvent, useCallback } from 'react';
import { useAppShell } from './context';

type TriggerActions = 'open' | 'close' | 'toggle';

type AppShellTriggerIconProps = IconButtonProps & {
  actionType?: TriggerActions;
};

export const AppShellTriggerIcon: FC<AppShellTriggerIconProps> = (props) => {
  const { actionType = 'toggle', onClick, ...buttonProps } = props;

  const { onOpen, onClose, onToggle } = useAppShell();

  const onTrigger = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      switch (actionType) {
        case 'close':
          onClose();
          break;

        case 'open':
          onOpen();
          break;

        case 'toggle':
          onToggle();
          break;
      }

      if (onClick) {
        onClick(e);
      }
    },
    [actionType, onClick]
  );

  return <IconButton {...buttonProps} onClick={onTrigger} />;
};
