'use client';

import MuiTextField, {
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form';

export type TextareaInputProps<T extends FieldValues = FieldValues> = Omit<
  MuiTextFieldProps,
  'name'
> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  rows?: number;
  /**
   * You override the MUI's TextField component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of TextField.
   */
  component?: typeof MuiTextField;
};

export function TextareaInput<TFieldValues extends FieldValues = FieldValues>(
  props: TextareaInputProps<TFieldValues>
) {
  const {
    control,
    name,
    rows = 4,
    component: TextFieldComponent = MuiTextField,
    helperText = undefined,
    ...baseProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, ...field },
        fieldState: { invalid, error },
      }) => (
        <TextFieldComponent
          inputRef={ref}
          multiline
          rows={rows}
          error={invalid}
          helperText={invalid && error?.message ? error?.message : helperText}
          {...baseProps}
          {...field}
        />
      )}
    />
  );
}
