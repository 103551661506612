'use client';

import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';
import type { FC } from 'react';

export type ButtonProps = LoadingButtonProps;

export const Button: FC<LoadingButtonProps> = (props) => (
  <LoadingButton startIcon={null} {...props} />
);
