'use client';

import MuiBox, { type BoxProps as MuiBoxProps } from '@mui/material/Box';
import { motion } from 'framer-motion';
import React from 'react';

const BoxComponent = React.forwardRef(
  (props: Omit<MuiBoxProps, 'component'>, ref) => (
    <MuiBox {...props} component="div" ref={ref} />
  )
);

export const MotionBox = motion(BoxComponent);
