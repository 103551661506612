'use client';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Container, Paper, Stack, Typography } from '@mui/material';
import type { FC } from 'react';

type Props = {
  description: string;
  elevation?: number;
};

export const NoResultCard: FC<Props> = (props: Props) => {
  const { description, elevation = 3 } = props;

  return (
    <Stack direction="row" justifyContent="center">
      <Container maxWidth="xs">
        <Paper elevation={elevation}>
          <Stack direction="column" gap={2} alignItems="center" padding={3}>
            <HighlightOffRoundedIcon fontSize="large" color="primary" />
            <Typography textAlign="center">{description}</Typography>
          </Stack>
        </Paper>
      </Container>
    </Stack>
  );
};
