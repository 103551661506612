'use client';

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, {
  type RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import Stack from '@mui/system/Stack';
import type { ReactNode } from 'react';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form';

export type RadioGroupProps<T extends FieldValues = FieldValues> = Omit<
  MuiRadioGroupProps,
  'name'
> & {
  label?: ReactNode;
  helperText?: string;
  disabled?: boolean;
  radios: { value: string; label: string }[];
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  /**
   * You override the MUI's MuiCheckbox component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of MuiCheckbox.
   */
  component?: typeof MuiRadioGroup;
};

export function RadioGroup<TFieldValues extends FieldValues = FieldValues>(
  props: RadioGroupProps<TFieldValues>
) {
  const {
    label = undefined,
    helperText = undefined,
    disabled = false,
    radios,
    control,
    name,
    component: RadioGroupComponent = MuiRadioGroup,
    ...baseProps
  } = props;

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { ref, ...field },
          fieldState: { invalid, error },
        }) => (
          <Stack flexDirection="row" alignItems="center">
            {label && <Typography>{label} :</Typography>}
            <FormControl hiddenLabel={!label} error={invalid}>
              <RadioGroupComponent {...baseProps} {...field}>
                {radios.map((radio) => (
                  <FormControlLabel
                    key={radio.value}
                    value={radio.value}
                    label={radio.label}
                    disabled={disabled}
                    control={<Radio />}
                  />
                ))}
                {((invalid && error?.message) || helperText) && (
                  <FormHelperText sx={{ marginLeft: 0 }}>
                    {invalid && error?.message ? error?.message : helperText}
                  </FormHelperText>
                )}
              </RadioGroupComponent>
            </FormControl>
          </Stack>
        )}
      />
    </>
  );
}
