'use client';

import {
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import {
  Box,
  ListItem,
  MenuItem,
  type MenuItemProps,
  Stack,
} from '@mui/material';
import PopupState, {
  bindHover,
  bindTrigger,
  bindFocus,
  bindMenu,
  type InjectedProps as InjectedPopupstate,
} from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import Link from 'next/link';
import {
  type ComponentProps,
  type FC,
  ReactElement,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type CascadingContextT = {
  parentPopupState: InjectedPopupstate | null;
  rootPopupState: InjectedPopupstate | null;
};

const CascadingContext = createContext<CascadingContextT>({
  parentPopupState: null,
  rootPopupState: null,
});

export const useCascadingContext = (): CascadingContextT & {
  rootPopupState: NonNullable<CascadingContextT['rootPopupState']>;
} => {
  const ctx = useContext(CascadingContext);

  if (!ctx) {
    throw new Error(
      'useCascadingContext() must be used inside <CascadingMenu/>'
    );
  }

  return ctx as CascadingContextT & {
    rootPopupState: NonNullable<CascadingContextT['rootPopupState']>;
  };
};

type CascadingMenuItemProps = Omit<MenuItemProps, 'onClick'> & {
  onClick?: (event: Event) => void;
  chevron?: 'left' | 'right';
  arrow?: 'left' | 'right';
  href?: string | undefined;
  target?: HTMLAnchorElement['target'];
};

export const CascadingMenuItem: FC<CascadingMenuItemProps> = (props) => {
  const {
    onClick,
    href,
    children,
    chevron = undefined,
    arrow = undefined,
    ...rest
  } = props;

  const { rootPopupState } = useCascadingContext();

  const handleClick = useCallback(
    (e: unknown) => {
      if (rootPopupState) {
        rootPopupState.close();
      }
      if (onClick) onClick(e as Event);
    },
    [rootPopupState, onClick]
  );

  return (
    <MenuItem
      {...rest}
      component={href ? 'a' : 'li'}
      LinkComponent={href ? Link : undefined}
      href={href}
      onClick={handleClick}
    >
      {chevron === 'left' && <ChevronLeft sx={{ marginLeft: -1 }} />}
      {!chevron && arrow === 'left' && <ArrowLeft fontSize="small" />}
      <Stack component="div" direction="row" flexGrow={1}>
        {children}
      </Stack>
      {chevron === 'right' && <ChevronRight sx={{ marginRight: -1 }} />}
      {!chevron && arrow === 'right' && <ArrowRight fontSize="small" />}
    </MenuItem>
  );
};

type CascadingMenuListItemProps = ComponentProps<typeof ListItem>;

export const CascadingMenuListItem: FC<CascadingMenuListItemProps> = (
  props
) => {
  const { onClick, ...rest } = props;

  const { rootPopupState } = useCascadingContext();

  // const handleClick = useCallback(
  //     (event: ReactMouseEvent<HTMLLIElement, MouseEvent>) => {
  //         rootPopupState.close()
  //         if (onClick) onClick(event)
  //     },
  //     [rootPopupState, onClick]
  // )

  return <ListItem {...rest} />;
};

type CascadingMenuProps = Omit<ComponentProps<typeof HoverMenu>, 'open'> & {
  popupState: InjectedPopupstate;
};

const CascadingMenu: FC<CascadingMenuProps> = (props) => {
  const { popupState, ...rest } = props;

  const { rootPopupState } = useContext(CascadingContext);

  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...rest} {...bindMenu(popupState)} suppressHydrationWarning />
    </CascadingContext.Provider>
  );
};

type CascadingSubmenuProps = {
  title: ReactNode;
  titleProps?: ComponentProps<typeof MenuItem>['sx'];
  chevron?: 'left' | 'right';
  arrow?: 'left' | 'right';
  disabled?: boolean;
} & Pick<ComponentProps<typeof PopupState>, 'popupId'> &
  Omit<CascadingMenuProps, 'popupState'>;

export const CascadingSubmenu: FC<CascadingSubmenuProps> = (props) => {
  const {
    title,
    titleProps = undefined,
    popupId,
    chevron = undefined,
    arrow = undefined,
    disabled = false,
    ...rest
  } = props;

  const { parentPopupState } = useCascadingContext();

  return (
    <PopupState
      popupId={popupId}
      variant="popover"
      parentPopupState={parentPopupState}
      disableAutoFocus
    >
      {(popupState) => (
        <>
          <MenuItem
            sx={titleProps}
            {...bindHover(popupState)}
            disabled={disabled}
          >
            {chevron === 'left' && <ChevronLeft sx={{ marginLeft: -1 }} />}
            {!chevron && arrow === 'left' && <ArrowLeft fontSize="small" />}
            <Box component="div" flexGrow={1}>
              {title}
            </Box>
            {chevron === 'right' && <ChevronRight sx={{ marginRight: -1 }} />}
            {!chevron && arrow === 'right' && <ArrowRight fontSize="small" />}
          </MenuItem>
          <CascadingMenu
            //   classes={{ ...rest.classes, paper: classes.submenu }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            {...rest}
            popupState={popupState}
            suppressHydrationWarning
          />
        </>
      )}
    </PopupState>
  );
};

type CascadingHoverMenusProps = Omit<
  ComponentProps<typeof PopupState>,
  'children'
> & {
  button: (props: ReturnType<typeof bindHover | typeof bindFocus>) => ReactNode;
  action?: 'click' | 'hover';
  cascadingMenuProps?: Omit<
    ComponentProps<typeof HoverMenu>,
    'open' | 'children'
  >;
  children: ReactNode | ReactNode[];
};

export const CascadingHoverMenus: FC<CascadingHoverMenusProps> = (props) => {
  const {
    button,
    cascadingMenuProps,
    children,
    action = 'hover',
    ...rest
  } = props;

  return (
    <PopupState {...rest}>
      {(popupState) => (
        <>
          {button(
            action === 'click' ? bindFocus(popupState) : bindHover(popupState)
          )}
          <CascadingMenu
            popupState={popupState}
            {...cascadingMenuProps}
            suppressHydrationWarning
          >
            {children}
          </CascadingMenu>
        </>
      )}
    </PopupState>
  );
};

export const CascadingMenus = CascadingHoverMenus;
