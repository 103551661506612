'use client';

import { type PaletteMode, useMediaQuery } from '@mui/material';
import { type FC, type ReactNode, useCallback, useState } from 'react';
import { ColorModeContext } from './context';

export const ColorModeProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const { children } = props;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = useState<PaletteMode>(
    prefersDarkMode ? 'dark' : 'light'
  );

  const toggleColorMode = useCallback(() => {
    setMode((prevMode: PaletteMode) =>
      prevMode === 'light' ? 'dark' : 'light'
    );
  }, []);

  return (
    <ColorModeContext.Provider
      value={{
        toggleColorMode,
        prefersDarkMode,
        mode,
      }}
    >
      {children}
    </ColorModeContext.Provider>
  );
};
