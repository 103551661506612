'use client';

import { Box, Stack, Typography } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { type ComponentProps, type FC, useMemo } from 'react';
import styles from './po-loader.module.css';

type Props = {
  scale?: number;
  texts?: string[];
  sx?: ComponentProps<typeof Box>['sx'];
  color?: keyof Theme['palette'];
};

export const PoLoader: FC<Props> = (props) => {
  const { scale = 1, texts = [], sx = {}, color = 'primary' } = props;

  const theme = useTheme();

  const svgColor = useMemo(
    () =>
      color in theme.palette &&
      typeof theme.palette[color] === 'object' &&
      'main' in theme.palette[color] &&
      typeof theme.palette[color].main === 'string'
        ? theme.palette[color].main
        : theme.palette.primary.main,
    [theme, color]
  );

  return (
    <Stack className={styles['triangle-loader-container']}>
      <Box
        component="div"
        className={styles.loader}
        sx={{ transform: `scale(${scale})`, ...sx }}
      >
        <svg viewBox="0 0 86 80" color={svgColor ?? theme.palette.primary.main}>
          <polygon points="43 8 79 72 7 72" />
        </svg>
      </Box>
      {texts.length !== 0 && (
        <Box component="div" className={styles['loading-phrases-container']}>
          <Box component="div" className={styles['loading-phrases']}>
            {texts.map((text, i) => (
              <Typography key={text}>{text}</Typography>
            ))}
          </Box>
        </Box>
      )}
    </Stack>
  );
};
