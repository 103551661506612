'use client';

import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import type { FC } from 'react';

export type LinkProps = MuiLinkProps & NextLinkProps;

export const Link: FC<LinkProps> = (props) => (
  <MuiLink {...props} component={NextLink} />
);
