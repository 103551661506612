'use client';

import type { StyledComponent } from '@emotion/styled';
import MuiLinearProgress, {
  linearProgressClasses,
  type LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const LinearProgress: StyledComponent<LinearProgressProps> = styled(
  MuiLinearProgress
)(({ theme }) => ({
  height: 8,
  borderRadius: 1_000_000,
  backgroundColor: theme.palette.background.paper,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1_000_000,
    backgroundImage:
      'linear-gradient(to right, rgb(0, 156, 160) 0%, rgb(0, 206, 209) 100%)',
  },
}));
