'use client';

import type { StyledComponent } from '@emotion/styled';
import MuiLinearProgress, {
  linearProgressClasses,
  type LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const AccuracyProgress: StyledComponent<LinearProgressProps> = styled(
  MuiLinearProgress
)(({ theme, value }) => {
  const MAX = 255;
  const CENT = 100;
  const val = value ?? 0;
  const offset = CENT - val;

  const green = (MAX * val) / CENT;
  const red = MAX - green;

  const linearGradient = `linear-gradient(to right, rgb(255, 0, 0) ${offset}%, rgb(${red}, ${green}, 0) 100%)`;

  return {
    height: 8,
    borderRadius: 1_000_000,
    backgroundColor: theme.palette.background.paper,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 1_000_000,
      backgroundImage: linearGradient,
    },
  };
});
