'use client';

import MuiCheckbox, {
  type CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { CheckboxInput } from '../checkbox-input';

export type CheckboxButtonInputProps<T extends FieldValues = FieldValues> =
  Omit<MuiCheckboxProps, 'name'> & {
    label?: ReactNode;
    helperText?: string;
    //   validation?: ControllerProps['rules']
    name: Path<T>;
    //   parseError?: (error: FieldError) => string
    control: Control<T>;
    /**
     * You override the MUI's TextField component by passing a reference of the component you want to use.
     *
     * This is especially useful when you want to use a customized version of TextField.
     */
    component?: typeof MuiCheckbox;
  };

const BaseIcon = styled('span')(({ theme }) => ({
  bgcolor: theme.palette.background.default,
  boxSizing: 'content-box',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  'input:hover ~ &': {
    borderColor: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const CheckedIcon = styled(BaseIcon)(({ theme, color }) => ({
  borderColor: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightBold,
}));

export function CheckboxButtonInput<
  TFieldValues extends FieldValues = FieldValues,
>(props: CheckboxButtonInputProps<TFieldValues>) {
  const {
    label = undefined,
    component: CheckboxComponent = MuiCheckbox,
    ...others
  } = props;

  return (
    <CheckboxInput
      {...others}
      component={(props: MuiCheckboxProps) => (
        <MuiCheckbox
          {...props}
          icon={<BaseIcon children={label} />}
          checkedIcon={<CheckedIcon children={label} />}
          disableRipple
        />
      )}
    />
  );
}
